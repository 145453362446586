<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="lg" :title="`${this.$route.params.userId ? 'View/Edit' : 'Create'} User`" @hidden="closeModal">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12">
          <b-form-group label="Name" :invalid-feedback="getValidationMessage('name')" :state="!validationErrors.name">
            <b-form-input type="text" v-model="editedUser.name" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12" md="6">
          <b-form-group label="Email Address" :invalid-feedback="getValidationMessage('email')" :state="!validationErrors.email">
            <b-form-input type="email" v-model="editedUser.email" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
        <b-form-group label="Phone Number" :invalid-feedback="getValidationMessage('phone')" :state="!validationErrors.phone">
          <b-form-input type="tel" v-model="editedUser.phone" />
        </b-form-group>
      </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12" md="6">
          <b-form-group label="Password" :invalid-feedback="getValidationMessage('password')" :state="!validationErrors.password">
            <b-form-input type="password" v-model="editedUser.password" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Confirmed Password" :invalid-feedback="getValidationMessage('password_confirmation')" :state="!validationErrors.password_confirmation">
            <b-form-input type="password" v-model="editedUser.password_confirmation" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12">
          <b-form-group label="User Type" :invalid-feedback="getValidationMessage('type')" :state="!validationErrors.type">
            <b-form-radio-group buttons button-variant="light" class="w-100" :options="[
                { value: 'cleaner', text: 'Cleaner', disabled: editedUser.id > 0 },
                { value: 'owner', text: 'Owner', disabled: editedUser.id > 0 },
                { value: 'maintenance', text: 'Maintenance', disabled: editedUser.id > 0 },
                { value: 'staff', text: 'Staff', disabled: editedUser.id > 0 }
            ]" v-model="editedUser.type" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" v-if="editedUser.type !== 'owner'">
          <b-form-group label="Contracted Hours" :invalid-feedback="getValidationMessage('contracted_hours')" :state="!validationErrors.contracted_hours">
            <b-form-input type="number" v-model="editedUser.contracted_hours" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" v-if="editedUser.type !== 'owner'">
          <b-form-group label="Holiday Allowance" :invalid-feedback="getValidationMessage('holiday_allowance')" :state="!validationErrors.holiday_allowance">
            <b-form-input type="number" v-model="editedUser.holiday_allowance" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="border-top mt-2 pt-4" v-if="!loading && !error">
        <b-col cols="12">
          <p class="h5 mb-4 font-weight-bold">Address Information</p>
          <b-form-group label="Address Line 1" :invalid-feedback="getValidationMessage('address.address_line_1')" :state="!(validationErrors['address.address_line_1'])">
            <b-form-input type="text" v-model="editedUser.address.address_line_1" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Address Line 2" :invalid-feedback="getValidationMessage('address.address_line_2')" :state="!(validationErrors['address.address_line_2'])">
            <b-form-input type="text" v-model="editedUser.address.address_line_2" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="City" :invalid-feedback="getValidationMessage('address.city')" :state="!(validationErrors['address.city'])">
            <b-form-input type="text" v-model="editedUser.address.city" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="County" :invalid-feedback="getValidationMessage('address.county')" :state="!(validationErrors['address.county'])">
            <b-form-input type="text" v-model="editedUser.address.county" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Postcode" :invalid-feedback="getValidationMessage('address.postcode')" :state="!(validationErrors['address.postcode'])">
            <b-form-input type="text" v-model="editedUser.address.postcode" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="border-top mt-2 pt-4" v-if="!loading && !error">
        <b-col cols="12">
          <p class="font-weight-bold h4">Permissions</p>
          <table class="table">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th class="text-center" style="width: 5rem">View</th>
                <th class="text-center" style="width: 5rem">Edit</th>
                <th class="text-center" style="width: 5rem">Create</th>
                <th class="text-center" style="width: 5rem">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="section" v-for="section in sections">
                <th>{{ section }}</th>
                <td class="text-center"><b-checkbox v-model="permissions[section + '/View']"/></td>
                <td class="text-center"><b-checkbox v-model="permissions[section + '/Edit']"/></td>
                <td class="text-center"><b-checkbox v-model="permissions[section + '/Create']"/></td>
                <td class="text-center"><b-checkbox v-model="permissions[section + '/Delete']"/></td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="saveUser" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">{{ $route.params.userId ? 'Update' : 'Create' }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import handleErrors from '../../mixins/handleErrors';

export default {
  mixins: [handleErrors],
  emits: ['hidden'],
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      overtimeEligible: 0,
      expectedHours: 0,
      hourlyRate: 0,
      editedUser: {
        type: '',
        name: '',
        email: '',
        phone: '',
        password: '',
        related_id: 0,
        password_confirmation: '',
        contracted_hours: 0,
        address: {},
      },
      loading: false,
      saving: false,
      error: '',
      sections: [
        'Customers',
        'Guests',
        'Properties',
        'Bookings',
        'Jobs',
        'Rotas',
        'Team management',
        'Stock management',
        'Absences',
        'Timesheets',
        'Statements',
        'Recurring costs',
        'Owner contact details',
        'Checklist Templates',
        'Users',
        'Maintenances'
      ],
      permissions: {},
    };
  },
  props: {
    user: Object,
    id: String,
  },
  async mounted() {
    if (this.$route.params.userId && this.user) {
      this.loading = true;

      try {
        const user = await this.fetchSingle(this.$route.params.userId);
        user.address = !user.address ? {} : user.address;
        this.editedUser = { address: {}, ...user };
        this.hourlyRate = user.cleaner?.hourly_rate;
        this.expectedHours = user.cleaner?.expected_hours;
        this.overtimeEligible = user.cleaner?.overtime_eligible;
        this.permissions = user.permissions.map(p => ({ [p.name]: true}))
          .reduce((acc, curr) => ({ ...acc, ...curr }), {})
        this.loading = false;
      } catch (err) {
        this.error = "Can not load user. Please try again later.";
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('users', ['fetch', 'fetchSingle', 'updateOrCreate']),
    closeModal() {
      this.editedUser = {
        type: '',
        name: '',
        email: '',
        phone: '',
        password: '',
        related_id: 0,
        password_confirmation: '',
        address: {},
        contracted_hours: 0
      };
      this.hourlyRate = 0;
      this.expectedHours = 0;
      this.overtimeEligible = 0;
      this.permissions = {};
      this.validationErrors = {};
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
      this.$emit('hidden')
    },
    saveUser() {
      this.saving = true;
      this.updateOrCreate({
        ...this.editedUser,
        hourly_rate: this.hourlyRate,
        expected_hours: this.expectedHours,
        overtime_eligible: this.overtimeEligible,
        permissions: Object.entries(this.permissions).filter(([,v]) => v).map(([p,]) => p)
      }).then(() => {
        this.saving = false;
        this.genericError = undefined;
        this.closeModal();
        return this.fetch();
      }).catch(err => {
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    }
  },
  watch: {
    user(value) {
      if (value === undefined) {
        return;
      }

      value.address = !value.address ? {} : value.address;
      this.editedUser = { address: {}, ...value };
      this.hourlyRate = value.cleaner?.hourly_rate;
      this.expectedHours = value.cleaner?.expected_hours;
      this.overtimeEligible = value.cleaner?.overtime_eligible;
      this.permissions = value.permissions.map(p => ({ [p.name]: true}))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {});
    }
  }
}
</script>
