<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <edit-user @hidden="selectedUser = undefined" id="edit-user" :user="selectedUser" />
    <sticky-header :title="$route.meta.title">
      <template #buttons>
        <b-checkbox class="align-self-center mr-2" v-model="showDeleted">Show Deleted Users</b-checkbox>
        <b-button class="ml-2" variant="primary" :to="{name: 'users.create'}"><fa-icon class="mr-2" icon="plus" />Create</b-button>
      </template>
    </sticky-header>
    <div class="flex-fill p-3">
      <b-row class="mb-3">
        <b-col cols="8">
          <b-form-input debounce="500" @update="load" placeholder="Search" type="text" v-model="query" />
        </b-col>
        <b-col cols="4">
          <b-form-select v-model="type" @change="load" :options="types"></b-form-select>
        </b-col>
      </b-row>
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <b-list-group class="mb-4" v-if="!loading">
        <b-list-group-item class="d-flex" :key="item.id" v-for="item in data">
          <div class="align-self-center flex-fill">
            <p class="h5">{{ item.name }} <b-badge pill variant="primary">{{ item.type.toUpperCase() }}</b-badge></p>
            <p class="mb-0 text-muted">{{ item.email }}</p>
          </div>
          <div class="align-self-center d-flex flex-md-row flex-column">
            <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="edit(item.id)" variant="primary" v-b-popover.top.hover="`Edit User`"><fa-icon icon="pen" /></b-button>
            <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="trash(item.id)" variant="danger" v-b-popover.top.hover="`Deactivate User`" v-if="!item.deleted_at"><fa-icon icon="trash" /></b-button>
            <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="restore(item.id)" variant="success" v-b-popover.top.hover="`Restore User`" v-if="item.deleted_at"><fa-icon icon="trash-restore" /></b-button>
            <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="unauth(item.id)" variant="secondary" v-b-popover.top.hover="`Force Sign Out Everywhere`" v-if="!item.deleted_at"><fa-icon icon="sign-out-alt" /></b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
      <b-pagination-nav :link-gen="paginationLinkGen" :number-of-pages="lastPage" use-router v-if="lastPage > 1" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import EditUser from './Modals/EditUser.vue'
import StickyHeader from '../components/StickyHeader.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: { EditUser, StickyHeader },
  data() {
    return {
        type: '',
        types: [
        { value: '', text: 'Show All' },
        { value: 'cleaner', text: 'Cleaner' },
        { value: 'owner', text: 'Owner' },
          { value: 'staff', text: 'Staff' },
          { value: 'maintenance', text: 'Maintenance' },
        ],
        query: '',
        selectedUser: {},
        showDeleted: false
    };
  },
  async mounted() {
    if (this.$route.meta.modalId) {
      this.$bvModal.show(this.$route.meta.modalId)
    }

    await this.fetch(this.$route.query.page);
  },
  computed: {
    ...mapState('users', ['data', 'loading', 'lastPage']),
  },
  methods: {
    ...mapActions('users', ['fetch', 'delete', 'forceUnauth']),
    moment,
    paginationLinkGen(pageNo) {
      return pageNo === 1
        ? {name: 'users', query: pickBy({...this.$route.params, page: null, query: this.query, type: this.type}, identity)}
        : {name: 'users', query: {...this.$route.params, page: pageNo, query: this.query, type: this.type}};
    },
    pluralise(count, term) {
      if (count === 1) return term;
      return `${term}s`;
    },
    edit(id) {
      this.selectedUser = this.data.find(c => c.id === id);

      if (!(this.$route.name === 'users.update' && this.$route.params.userId === id)) {
        this.$router.push({ name: 'users.update', params: { userId: id } });
      }
    },
    trash(id) {
      this.$bvModal.msgBoxConfirm("Are you sure you want to disable this user? You can later reinstate a user, at any time.", {
        title: 'Delete User',
        okText: 'Yes, Delete',
        okVariant: 'danger',
        cancelText: 'No',
        cancelVariant: 'light',
        centered: true
      }).then(shouldDelete => {
        if (!shouldDelete) {
          return;
        }

        return this.delete(id).then(() => this.fetch({ page: this.$route.query.page, showDeleted: this.showDeleted }));
      });
    },
    restore(id) {
      this.$bvModal.msgBoxConfirm("Are you sure you want to reinstate this user? They'll be able to log in with their old credentials.", {
        title: 'Reinstate User',
        okText: 'Yes, Reinstate',
        okVariant: 'success',
        cancelText: 'No',
        cancelVariant: 'light',
        centered: true
      }).then(shouldDelete => {
        if (!shouldDelete) {
          return;
        }

        return this.delete({ id, restore: true }).then(() => this.fetch({ page: this.$route.query.page, showDeleted: this.showDeleted }));
      });
    },
    unauth(id) {
      this.$bvModal.msgBoxConfirm("Are you sure you want to force sign out this user? They will be forced to log back in on all devices.", {
        title: 'Sign Out User',
        okText: 'Yes, Sign Out',
        okVariant: 'danger',
        cancelText: 'No',
        cancelVariant: 'light',
        centered: true
      }).then(shouldUnauth => {
        if (!shouldUnauth) {
          return;
        }

        return this.forceUnauth({id});
      });
    },
    async load() {
      return await this.fetch({
        query: this.query,
        type: this.type,
        page: this.$route.query.page,
        showDeleted: this.showDeleted
      });
    },
  },
  watch: {
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      if (newValue.name === value.name && newValue.query.page !== value.query.page) {
        await this.load();
      }

      return this.$bvModal.hide(value.meta.modalId);
    },
    showDeleted(newValue, oldValue) {
        if (newValue === oldValue) return;
        this.$route.query.page = 1;
        this.fetch({ page: this.$route.query.page, showDeleted: newValue });
    }
  }
}
</script>

<style>

</style>
